import React, { useEffect } from "react";
import { useState } from "react";
import {
  AiOutlineDown,
  AiOutlineRight,
  AiFillCaretRight,
} from "react-icons/ai";
import { ImCheckboxChecked } from "react-icons/im";

import "../style/products.css";
import Style from "../style/Style.module.css";
import { Link } from "react-scroll";

import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import OffringCarousel from "./OffringCarousel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Helmet } from "react-helmet";

const DynamicPage = ({ lang, setPageName }) => {
  let token = sessionStorage.getItem("Access_token");
  const { id, menuType } = useParams();
  const nevigate = useNavigate();
  const location = useLocation();
  const value = location?.state;

  // const menuId = value?.menu_id
  // const menuType = value?.menu_type
  const menuId = id;

  const [data, setData] = useState(false);
  const [form1, setForm1] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [show, setShow] = useState(false);
  const [overOffering, setOverOffering] = useState();
  const [loading, setLoading] = useState(false);
  const getData = () => {
    setLoading(true);
    axios
      .post("https://www.tripuragraminbank.org/api/custom-page.php", {
        lang: lang,
        menu_id: menuId,
        menu_type: menuType,
      })
      .then((response) => {
        setLoading(false);
        setData(response.data.responseResult);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    AOS.init({ duration: 1500 });
    getData();
  }, [lang, value]);

  const moveInternalPage = (el, pageName, menu_id, menu_type) => {
    nevigate(`/tgb/${menu_id}/${menu_type}/${pageName}`, {
      state: { menu_id, menu_type, pageName },
    });
  };

  const headerButtonStatus = data?.is_header_required;
  const isLoginReruires = data?.is_login_required;
  const righSliderStatus = data?.is_right_side_slider_required;
  const pagePath = data?.page_path;
  const pageName = data?.name;
  const headerButton = data?.header_btn;
  const section = data?.page_section;
  const sections = [];
  const RightButton = data?.right_side_btn;
  const rightSideMenu = data?.right_side_menu;
  const RightDropdown = rightSideMenu?.dropdown?.menu;
  let overOffer = [];
  const rightSideSlider = data?.right_side_slider;
  const rightSliderNo = data?.how_much_slider;
  const rightSliderShow = data?.is_header_required;

  const rightSliders = () => {
    let arr = [];
    for (let i = 0; i < rightSliderNo?.length; i++) {
      arr.push(
        <div class="mt-4" style={{ width: "80%" }}>
          <Carousel
            infiniteLoop={true}
            swipeable={false}
            autoPlay={true}
            showThumbs={false}
            stopOnHover={false}
          >
            {rightSideSlider[i].img.map((el) => {
              return (
                <div style={{ width: "100%", height: "30vh", padding: "0px" }}>
                  <img
                    style={{ height: "30vh", width: "100%", padding: "0px" }}
                    src={el}
                    alt=""
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      );
    }
    return arr;
  };

  const showElements = () => {
    let arr = [];
    for (const key in section) {
      sections.push(section[key]);
    }

    for (let i = 0; i < headerButton?.length; i++) {
      let pageSectionId = headerButton[i]?.pageId;
      let sectionType = headerButton[i]?.sectionType;
      let pageSection = sections[i];

      if (sectionType === "Slider") {
        arr.push(
          <div
            class="products2 my-4 "
            data-aos="fade-up"
            id={pageSection.pageId}
          >
            <Carousel
              infiniteLoop={true}
              swipeable={false}
              autoPlay={true}
              showThumbs={false}
              stopOnHover={false}
            >
              {pageSection?.Data?.map((el) => {
                return (
                  <div>
                    <img
                      style={{ width: "100%" }}
                      src={el.img}
                      alt=""
                    />
                  </div>
                );
              })}
            </Carousel>
            {!pageSection?.buttonName ? (
              ""
            ) : (
              <button
                class="btn btn-success mt-4"
                style={{ width: "fit-content" }}
                onClick={() => nevigate(`/${pageSection.buttonUrl}`)}
              >
                {pageSection?.buttonName}
              </button>
            )}
          </div>
        );
      } else if (sectionType === "SectionDetail") {
        arr.push(
          <div class="products3 text-start py-1" id={pageSection?.pageId}>
            <p class="fs-2 fw-semibold" data-aos="fade-up">
              {pageSection?.title}
            </p>
            {pageSection?.Data.map((el) => {
              return (
                <div style={{ overflowX: "auto", overflowY: "hidden" }}>
                  <table
                    class="table fs-5 text-secondary"
                    data-aos="fade-up"
                    dangerouslySetInnerHTML={{ __html: el.desc }}
                  ></table>
                </div>
              );
            })}
            {!pageSection?.buttonName ? (
              ""
            ) : (
              <button
                class="btn btn-success mt-4"
                style={{ width: "fit-content" }}
                onClick={() => nevigate(`/${pageSection.buttonUrl}`)}
              >
                {pageSection?.buttonName}
              </button>
            )}
          </div>
        );
      } else if (sectionType === "Features") {
        arr.push(
          <div class="products4 text-start py-3" id={pageSection?.pageId}>
            <p class="fs-2 fw-semibold" data-aos="fade-up">
              {pageSection?.title}
            </p>
            <div class="products4_1">
              {pageSection?.Data.map((details) => {
                return (
                  <div data-aos="fade-up">
                    <img src={details.img} alt="" />
                    <p class="py-3 m-0">{details.name}</p>
                  </div>
                );
              })}
            </div>
            {!pageSection?.buttonName ? (
              ""
            ) : (
              <button
                class="btn btn-success mt-4"
                style={{ width: "fit-content" }}
                onClick={() => nevigate(`/${pageSection.buttonUrl}`)}
              >
                {pageSection?.buttonName}
              </button>
            )}
          </div>
        );
      } else if (sectionType === "Points") {
        arr.push(
          <div class="products5 text-start py-4" id={pageSection?.pageId}>
            <p class="fs-2 fw-semibold" data-aos="fade-up">
              {pageSection?.title}
            </p>
            <div>
              {pageSection?.Data.map((el) => {
                return (
                  <p data-aos="fade-up" class="my-2">
                    <span style={{ color: "#FB7D33" }}>
                      <ImCheckboxChecked />
                    </span>
                    <span class="px-3">
                      <a
                        href={`${el.url}`}
                        target="_blanck"
                        style={{ color: "black" }}
                      >
                        {el.name}
                      </a>
                    </span>
                  </p>
                );
              })}
            </div>
            {!pageSection?.buttonName ? (
              ""
            ) : (
              <button
                class="btn btn-success mt-4"
                style={{ width: "fit-content" }}
                onClick={() => nevigate(`/${pageSection.buttonUrl}`)}
              >
                {pageSection?.buttonName}
              </button>
            )}
          </div>
        );
      } else if (sectionType === "Carts") {
        arr.push(
          <div class="products6 text-start py-4" id={pageSection?.pageId}>
            <p class="fs-2 fw-semibold" data-aos="fade-up">
              {pageSection?.title}
            </p>
            <div class="products6_1">
              {pageSection?.Data.map((el) => {
                return (
                  <div data-aos="fade-up">
                    <img class='img-fluid' src={el.img}  alt="" />
                    <p class="fs-5 mt-2 fw-semibold ">{el.name}</p>
                    <p class="text-secondry" style={{ fontSize: "15px" }}>
                      {el.desc}
                    </p>
                    <p
                      class="text-success fw-semibold m-0"
                      style={{ fontSize: "15px" }}
                      dangerouslySetInnerHTML={{ __html: el.text }}
                    ></p>
                  </div>
                );
              })}
            </div>
            {!pageSection?.buttonName ? (
              ""
            ) : (
              <button
                class="btn btn-success mt-4"
                style={{ width: "fit-content" }}
                onClick={() => nevigate(`/${pageSection.buttonUrl}`)}
              >
                {pageSection?.buttonName}
              </button>
            )}
          </div>
        );
      } else if (sectionType === "Faq") {
        // console.log("pageSection",pageSection);
        arr.push(
          <div class="products8 text-start py-4" id={pageSection?.pageId}>
            <p class="fs-2 fw-semibold" data-aos="fade-up">
              {pageSection?.title}
            </p>
            {pageSection?.Data.map((el, i) => {
              return (
                <div class="products8_1" data-aos="fade-up">
                  <div
                    class="faqs"
                    data-bs-toggle="collapse"
                    href={`#faqCollapse${i}`}
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <div class="qus">
                      <p style={{ color: show ? "#BB355A" : "black" }}>
                        {el.question}
                      </p>
                    </div>
                    <div class="qus">
                      <p
                        style={{
                          color: show ? "#BB355A" : "black",
                          transform: show ? "rotateX(180deg)" : "rotateX(0deg)",
                        }}
                      >
                        {<AiOutlineDown />}
                      </p>
                    </div>
                  </div>
                  <div
                    class="ans collapse"
                    id={`faqCollapse${i}`}
                    style={{
                      maxWidth: "1000px",
                      margin: "0",
                      transition: "1000ms",
                    }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: el.answer }}></p>
                  </div>
                </div>
              );
            })}
            {!pageSection?.buttonName ? (
              ""
            ) : (
              <button
                class="btn btn-success mt-4"
                style={{ width: "fit-content" }}
                onClick={() => nevigate(`/${pageSection.buttonUrl}`)}
              >
                {pageSection?.buttonName}
              </button>
            )}
          </div>
        );
      } else if (sectionType === "NeedAssisstance") {
        arr.push(
          <div class="products9 text-start py-4" id={pageSection?.pageId}>
            <p class="products9p my-2" data-aos="fade-up">
              {pageSection?.title}
            </p>
            {pageSection?.Data.map((el) => {
              return (
                <>
                  <p data-aos="fade-up">{el.text}</p>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                    data-aos="fade-up"
                  >
                    <div>
                      <img style={{ width: "90px" }} src={el.img} alt="" />
                    </div>
                    <div>
                      <p class="fw-bold m-0">Contact Center</p>
                      <p class="text-danger m-0">
                        {" "}
                        Direct Number : <span>{el.direct_number}</span>
                      </p>
                      <p class="text-danger m-0">
                        Mobile Number : <span>{el.mobile_number}</span>{" "}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
            {!pageSection?.buttonName ? (
              ""
            ) : (
              <button
                class="btn btn-success mt-4"
                style={{ width: "fit-content" }}
                onClick={() => nevigate(`/${pageSection.buttonUrl}`)}
              >
                {pageSection?.buttonName}
              </button>
            )}
          </div>
        );
      } else if (sectionType === "Gallery") {
        arr.push(
          <div class="py-4 text-start gallery">
            <p class="fs-2 fw-semibold">{pageSection?.title}</p>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 550: 2, 700: 3 }}
            >
              <Masonry>
                {pageSection?.Data?.map((el, i) => {
                  return (
                    <>
                      <img
                        src={el.img}
                        alt=""
                        data-bs-toggle="modal"
                        data-bs-target={`#exampleModal${i}`}
                      />
                      <div
                        class="modal   "
                        id={`exampleModal${i}`}
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                        style={{ margin: "auto auto" }}
                      >
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content">
                            <div class="text-end">
                              <button
                                type="button"
                                class="btn-close text-end"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>

                            <img
                              class="mb-4"
                              style={{ width: "90%", margin: "auto" }}
                              src={el.img}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        );
      } else if (sectionType === "OurOfferings") {
        overOffer.push(pageSection);
      }
    }

    return arr;
  };

  if (isLoginReruires == "Yes" && !token) {
    return nevigate("/log-in");
  }
  return (
    <div class="products">
      <Helmet>
            <meta charSet="utf-8" />
            <title>{pageName}</title>
            <meta name="description" content={pageName} />
        </Helmet>
      <div class="text-start px-4 py-2">
        <p class="text-secondary m-0" style={{ fontSize: "12px" }}>
          {" "}
          <span>{pagePath}</span>{" "}
        </p>
        <p class="fw-semibold" style={{ fontSize: "30px" }}>
          {pageName}
        </p>
      </div>
      <div class="row allProduct">
        <div class="col-md-8 px-5">
          {headerButtonStatus == "No" ? (
            ""
          ) : (
            <div class="products1">
              <table>
                <thead>
                  <tr class="d-flex justify-content-between">
                    {headerButton?.map((btn) => {
                      return (
                        <>
                          <Link
                            activeClass="active"
                            to={btn.pageId}
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                          >
                            <button class="btn px-3 py-2 products1Button">
                              {btn.buttonName}
                            </button>
                          </Link>
                        </>
                      );
                    })}
                  </tr>
                </thead>
              </table>
            </div>
          )}
          {showElements()}
        </div>
        <div class="col-md-4 md-8 p-0 productsRight ">
          <div class="productsRight1">
            {RightButton?.map((el) => {
              return (
                <>
                  {!el.title ? (
                    ""
                  ) : (
                    <a
                      type="button"
                      class="btn btn-outline-success px-3 py-2"
                      // onClick={() =>
                      //   nevigate(`/${el.url}`, { state: { pageName } })
                      // }
                      href={`/${el.url}`}
                      target="_blank"
                    >
                      {el.title}
                    </a>
                  )}
                </>
              );
            })}
          </div>

          <div class="productsRight2 text-start">
            <p class="fs-2 fw-semibold my-3" data-aos="fade-up">
              Other links
            </p>
            <div class="productsRight2_1 mb-5" data-aos="fade-up">
              <div class="productsRight2_1Div ">
                <p>{rightSideMenu?.pageName}</p>
              </div>
              {RightDropdown?.map((menu) => {
                return (
                  <>
                    <div
                      class="mx-4 mb-0 p-2 d-flex gap-2"
                      data-bs-toggle="collapse"
                      href={`#${menu.menuName?.split(" ").join("")}`}
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                      onClick={() =>
                        menu.submenu?.length
                          ? () => {}
                          : moveInternalPage(
                              "/tgb",
                              menu.menuName
                                .toLocaleLowerCase()
                                .split(" ")
                                .join("-"),
                              menu.menuId,
                              "sub_menu"
                            )
                      }
                    >
                      <p
                        style={{
                          color: "#BB355A",
                          fontWeight: "500",
                          fontSize: "18px",
                        }}
                        class="mb-0 "
                      >
                        {" "}
                        {menu.menuName}{" "}
                      </p>
                      {menu.submenu.length > 0 && (
                        <p
                          class="m-0 fw-bold"
                          style={{
                            transform: "rotate(90deg)",
                            transition: "1000ms",
                          }}
                        >
                          <span
                            style={{
                              color: "#BB355A",
                              fontWeight: "500",
                              fontSize: "18px",
                            }}
                          >
                            <AiFillCaretRight />
                          </span>
                        </p>
                      )}
                    </div>
                    {menu.submenu.length > 0 && (
                      <div
                        class="collapse p-0"
                        id={menu.menuName?.split(" ").join("")}
                        style={{
                          maxWidth: "1000px",
                          margin: "0",
                          transition: "1000ms",
                        }}
                      >
                        <div
                          class="card card-body p-0  m-0 mx-3"
                          style={{ border: "none", background: "transparent" }}
                        >
                          <ul style={{ listStyle: "none" }}>
                            {menu.submenu?.map((submenu) => {
                              return (
                                <li class="p-0" style={{ cursor: "pointer" }}>
                                  <a
                                    class="dropdown-item text-dark p-0"
                                    onClick={() => {
                                      moveInternalPage(
                                        "/tgb",
                                        submenu.submenuName
                                          .toLocaleLowerCase()
                                          .split(" ")
                                          .join("-"),
                                        submenu.submenuId,
                                        "child_menu",
                                        menu.menuName
                                          .toLocaleLowerCase()
                                          .split(" ")
                                          .join("-")
                                      );
                                    }}
                                  >
                                    {submenu.submenuName}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
              <br />
            </div>
          </div>

          {rightSliderShow == "Yes" ? rightSliders() : ""}
        </div>
      </div>

      {overOffer?.map((el) => {
        return (
          <div style={{ background: "#F5F5F8" }} id={el.pageId}>
            <p class="fs-1 fw-semibold">{el.title}</p>
            <OffringCarousel data={el.Data} />
          </div>
        );
      })}
    </div>
  );
};

export default DynamicPage;
