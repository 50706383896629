import React from "react";
import "./style/customerCare.css";
import { useState } from "react";
import HomeEmi from "./emi/HomeEmi";
import FixeDeposit from "./emi/FixeDeposit";
import RecurringDeposit from "./emi/RecurringDeposit";
import { Helmet } from "react-helmet";

const EmiCalculator = () => {
  const [amount, setAmount] = useState("100000");
  const [intrest, setIntrest] = useState("5");
  const [year, setYear] = useState("2");
  const [btnYearMonth, setBtnYearMonth] = useState("year");

  const [section, setSection] = useState("loan");
  return (
    <div class="customer">
         <Helmet>
            <meta charSet="utf-8" />
            <title>EMI-Calculator</title>
            <meta name="description" content="EMI-Calculator" />
        </Helmet>
      <div class="row">
        <div class="col-md-12 col-12 px-5 ">
          <div class="text-start p-2">
            <p class="text-secondary mt-4 mb-0" style={{ fontSize: "12px" }}>
              EMI-Calculator{" "}
            </p>
            <p class="fw-semibold mb-0" style={{ fontSize: "30px" }}>
              EMI-Calculator
            </p>
          </div>
          <div>
            <div class="bg-success p-2  d-flex gap-2 flex-wrap">
              <button
                class="btn btn-success  p-1 px-3 fw-bold"
                onClick={() => setSection("loan")}
              >
                Loan
              </button>
              <button
                class="btn btn-success  p-1 px-3 fw-bold"
                onClick={() => setSection("FixedDeposit")}
              >
                Fixed Deposit (FD)
              </button>
              <button
                class="btn btn-success  p-1 px-3 fw-bold"
                onClick={() => setSection("RecurringDeposit")}
              >
                Recurring Deposit (RD)
              </button>
            </div>
            {section == "loan" ? <HomeEmi /> : ""}
            {section == "FixedDeposit" ? <FixeDeposit /> : ""}
            {section == "RecurringDeposit" ? <RecurringDeposit /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmiCalculator;
