import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";

const FixeDeposit = () => {
  const [amount, setAmount] = useState(100000);
  const [intrest, setIntrest] = useState(1);
  const [year, setYear] = useState(12);
  const [emi, setEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [chartData, setChartData] = useState([]);

  const [chart1, setChart1] = useState(25);
  const [chart2, setChart2] = useState(18);

  const series = [chart1, chart2];
  const options = { labels: ["Total of Payment", "Total Interest Payable"] };

 
  const r = intrest / 100;
  const a1 = 1 + r / 4;
  const a2 = 4 * (year / 12);
  const a = Math.pow(a1, a2);

  const fd = (amount * a).toFixed(2);

  const calculateEMI = () => {
    setChart2(80);
    const r = intrest / 1200;
    const n = year;
    const emi = (amount * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    const totalInterest = emi * n - amount;
    const totalPayment = emi * n;
    setChart2(totalInterest);
    setChart1(totalPayment);

    setEmi(emi.toFixed(2));
    setTotalInterest(totalInterest.toFixed(2));
    setTotalPayment(totalPayment.toFixed(2));
    setChartData(
      [...Array(n).keys()].map((month) => ({
        month: month + 1,
        interest: ((amount - emi * month) * intrest) / (12 * 100),
        principal: emi - ((amount - emi * month) * intrest) / (12 * 100),
        total: emi * (month + 1),
      }))
    );
  };
  useEffect(() => {
    calculateEMI();
  }, [amount, year, intrest]);

  return (
    <div class="p-0 row m-0">
      <div
        class="col-md-5 col-12 text-start px-4 py-2"
        style={{ background: "#f3f3f3", height: "auto" }}
      >
        <label class="form-label mt-2 fw-bold">Fixe Deposit (FD)</label>
        <select
          class="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
        >
          <option selected>Are you a Senior Citizen</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>

        <label class="form-label mt-2">Amount</label>
        <div class="input-group">
          <span class="input-group-text">@</span>
          <input
            type="text"
            class="form-control"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>

        <br />

        <input
          type="range"
          min="100000"
          max="10000000"
          step="100000"
          value={amount}
          style={{ width: "100%" }}
          onChange={(e) => setAmount(e.target.value)}
        />

        <br />

        <label class="form-label">Interest Rate</label>
        <div class="input-group">
          <span class="input-group-text ">%</span>
          <input
            type="text"
            class="form-control"
            value={intrest}
            onChange={(e) => setIntrest(e.target.value)}
          />
        </div>

        <br />

        <input
          type="range"
          min="0"
          max="15"
          step="0.5"
          class=""
          value={intrest}
          style={{ width: "100%" }}
          onChange={(e) => setIntrest(e.target.value)}
        />

        <br />

        <label class="form-label mt-4">Period In (Month)</label>
        <div class="input-group my-1">
          <span class="input-group-text">Time</span>
          <input
            type="text"
            class="form-control"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </div>
        <br />
        <input
          type="range"
          min="0"
          max="120"
          step="1"
          class=""
          value={year}
          style={{ width: "100%" }}
          onChange={(e) => setYear(e.target.value)}
        />
      </div>

      {/*------  */}

      <div class="col-md-7 col-12 row m-0 p-0 ">
        <div class="col-md-5 col-12 p-0">
          <div
            class="p-0"
            style={{
              height: "100%",
              display: "grid",
              gridTemplateRows: "33.3% 33.3% 33.3%",
            }}
          >
            <div class="border p-3 text-start d-grid align-items-center">
              <p class="text-secondary fw-semibold m-0">Fixed Deposit Amount</p>
              {/* <p class=' fs-5 fw-bold'>₹ {emi == "NaN" ? 0 :emi}</p>  */}
              <p class=" fs-5 fw-bold">₹ {amount}</p>
            </div>
            <div class="border p-3 text-start d-grid align-items-center">
              <p class="text-secondary fw-semibold m-0">Rate Of Interest</p>
              {/* <p class=' fs-5 fw-bold'>₹ {totalInterest == "NaN" ? 0 :totalInterest}</p>  */}
              <p class=" fs-5 fw-bold">% {intrest}</p>
            </div>
            <div class="border p-3 text-start d-grid align-items-center">
              <p class="text-secondary fw-semibold m-0">
                Maturity Value (Approx.)
              </p>
              {/* <p class=' fs-5 fw-bold'>₹{MaturityValue}  </p> */}
              <p class=" fs-5 fw-bold">₹{fd} </p>
            </div>
          </div>
        </div>

        <div class="col-md-7 col-12 d-grid align-items-center">
          <Chart
            type="pie"
            width={300}
            height={400}
            series={series}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default FixeDeposit;
