import React, { useEffect, useState } from "react";
import "./style/navbar_copy.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TextTyper from "text-type-animation-effect-react";
import Style from "./style/Style.module.css";
import TextTransition, { presets } from "react-text-transition";
import AOS from "aos";
import "aos/dist/aos.css";
import swal from "sweetalert";

import { BsChevronDown } from "react-icons/bs";
import { BiLogIn, BiLogOut } from "react-icons/bi";
import {
  BsFacebook,
  BsFillTelephoneFill,
  BsSearch,
  BsTelephone,
} from "react-icons/bs";
import { AiOutlineDown, AiOutlineMenu, AiOutlineRight } from "react-icons/ai";
import { IoMdMail } from "react-icons/io";
import { IoIosContact } from "react-icons/io";
import { GrInstagram, GrLogout } from "react-icons/gr";
import { FaMinus, FaTwitter, FaUserSlash } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { CgLayoutGrid, CgProfile } from "react-icons/cg";
import { GoPlus } from "react-icons/go";
import { ImCross } from "react-icons/im";

import axios from "axios";

const TEXTS = [
  "TRIPURA GRAMIN BANK",
  "ত্রিপুরা গ্রামীণ ব্যাংক",
  "त्रिपुरा ग्रामीण बैंक",
];
const TEXTS2 = [
  "TRIPURA GRAMIN BANK",
  "ত্রিপুরা গ্রামীণ ব্যাংক",
  "त्रिपुरा ग्रामीण बैंक",
];

const inputData = [
  {
    name: "Applecation",
  },
  {
    name: "PassBook",
  },
  {
    name: "Accounts",
  },
  {
    name: "Account Details",
  },
  {
    name: "Branch",
  },
];

const emploginButton = [
  {
    lang: "en",
    title: "Employee",
    url: "log-in",
  },
  {
    lang: "hi",
    title: "कर्मचारी",
    url: "log-in",
  },
  {
    lang: "bn",
    title: "কর্মচারী",
    url: "log-in",
  },
];

const bordloginButton = [
  {
    lang: "en",
    title: "Board Members",
    url: "log-in",
  },
  {
    lang: "hi",
    title: "निदेशकमंडल ",
    url: "log-in",
  },
  {
    lang: "bn",
    title: "পরিচালনা",
    url: "log-in",
  },
];

const Navbar = ({ lang, setPageName, searchName }) => {
  const [index, setIndex] = useState(0);

  const [login, setLogin] = useState(false);

  const [show, setShow] = useState({});

  const [navData, setNavData] = useState([]);

  const [search, setSearch] = useState("");

  const [msearch, setMsearch] = useState(false);

  const [collapse, setCollapse] = useState({
    visible: "collapse show  ",
    hidden: "collapse",
  });
  const [idToCollapse, setIdToCollapse] = useState({});

  const [collapseChild, setCollapseChild] = useState({
    visible: "collapse show  ",
    hidden: "collapse",
  });
  const [idToCollapseChild, setIdToCollapseChild] = useState({});

  const [language, setLanguage] = useState("en");
  const empButtonName = emploginButton.filter((el) => el.lang == language);
  const bordButtonName = bordloginButton.filter((el) => el.lang == language);


  const getNavData = () => {
    axios
      .post("https://www.tripuragraminbank.org/api/navbar.php", {
        lang: language,
      })
      .then((response) => {
        setNavData(response.data.responseResult);
      })
      .catch((error) => console.log("error", error));
  };

  searchName(search);

  useEffect(() => {
    lang(language);
    getNavData();
  }, [language]);

  useEffect(() => {
    AOS.init({ duration: 1500 });
    const nav = document.querySelector(".navbarMain");
    let lastScrollY = window.scrollY;
    window.addEventListener("scroll", () => {
      if (lastScrollY < window.scrollY) {
        nav.classList.add("navbarMain--hidden");
      } else {
        nav.classList.remove("navbarMain--hidden");
      }
      lastScrollY = window.scrollY;
    });
  }, [language]);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      8000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  });
  useEffect(() => {
    let token = sessionStorage.getItem("Access_token");
    if (token) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  });

  const time = new Date().toLocaleTimeString();
  const dayDate = new Date().toLocaleDateString("en-in", {
    weekday: "long",
    month: "short",
    day: "numeric",
  });

  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.clear("Access_token");
    swal("User Log-Out Successfully !", {
      buttons: false,
      timer: 1500,
    });
    navigate("/");
  };

  const moveMenuPage = (el, id) => {
    if (el !== "#") {
      navigate(el);
      setShow(false);
    }

    let idToClsp = {};
    if (idToCollapse[id] == collapse.visible) {
      idToClsp = { ...idToCollapse, [id]: collapse.hidden };
    } else {
      idToClsp = { ...idToCollapse, [id]: collapse.visible };
    }
    setIdToCollapse(idToClsp);
  };

  const moveInternalPage = (el, pageName, menu_id, menu_type) => {
    navigate(`/tgb/${menu_id}/${menu_type}/${pageName}`, {
      state: { menu_id, menu_type, pageName },
    });
    setShow(false);
  };

  const openChildMenu = (id) => {
    let idToClsp = {};
    if (idToCollapseChild[id] == collapseChild.visible) {
      idToClsp = { ...idToCollapseChild, [id]: collapseChild.hidden };
    } else {
      idToClsp = { ...idToCollapseChild, [id]: collapseChild.visible };
    }
    setIdToCollapseChild(idToClsp);
  };

  const mSearch = () => {
    navigate("/search-results");
    setMsearch(false);
  };

  const dropdown = navData.map((el) => el.dropdown);

  // console.log(navData);

  const navbar = () => {
    let arr = [];
    for (let i = 0; i < navData.length; i++) {
      const dropdown = navData[i].dropdown?.menu.map((el) => el.menuName);
      // console.log(navData[i].en_pageName);
      arr.push(
        <>
          <button
            type="button"
            class="btn btn-success rounded-0 py-2 px-4 open fw-semibold"
            id={navData[i].id}
            onMouseEnter={() => setShow({ [navData[i].id]: true })}
            onMouseLeave={() => setShow({ [navData[i].id]: false })}
            onClick={() => moveMenuPage(`${navData[i].url}`)}
          >
            {navData[i].pageName}{" "}
            {dropdown.length > 0 && (
              <span>
                <BsChevronDown />
              </span>
            )}
          </button>
          {show[navData[i].id] && dropdown.length > 0 && (
            <div
              class="collapseDropdown"
              onMouseEnter={() => setShow({ [navData[i].id]: true })}
              onMouseLeave={() => setShow({ [navData[i].id]: false })}
            >
              <div
                class="collapseShow"
                style={{
                  backgroundImage: `url('${navData[i].dropdown?.img}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "40% 90%",
                  backgroundPosition: "bottom 20px right 20px",
                }}
              >
                <ul class="collepsUl">
                  {navData[i].dropdown?.menu.map((menu) => {
                    return (
                      <>
                        <li
                          class="submenuDrop  "
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={
                            menu?.menuUrl == "#"
                              ? () =>
                                menu.submenu?.length
                                  ? () => { }
                                  : moveInternalPage(
                                    "/tgb",
                                    menu.en_pageName
                                      .toLocaleLowerCase()
                                      .split(" ")
                                      .join("-"),
                                    menu.menuId,
                                    "sub_menu"
                                  )
                              : () => navigate(`${menu?.menuUrl}`)
                          }
                        >
                          <p class=" mb-0">{menu.menuName}</p>
                          {menu.submenu?.length > 0 && (
                            <span>
                              <AiOutlineRight class="menuRight" />
                            </span>
                          )}{" "}
                        </li>

                        <ul
                          class="submenuDrop-dropdown-menu"
                          style={{
                            listStyle: "none",
                            width: "max-content !importtant",
                          }}
                        >
                          {menu.submenu?.map((submenu) => {
                            return (
                              <li>
                                <a
                                  class="submenuDrop-dropdown-item"
                                  onClick={
                                    submenu?.submenuUrl == "#"
                                      ? () => {
                                        moveInternalPage(
                                          "/tgb",
                                          submenu.en_pageName
                                            .toLocaleLowerCase()
                                            .split(" ")
                                            .join("-"),
                                          submenu.submenuId,
                                          "child_menu"
                                        );
                                      }
                                      : () => navigate(`${submenu?.submenuUrl}`)
                                  }
                                >
                                  {submenu.submenuName}
                                </a>
                              </li>

                              // <li ><a class="submenuDrop-dropdown-item" onClick={()=>{ moveInternalPage('/tgb',submenu.en_pageName.toLocaleLowerCase().split(' ').join("-"),submenu.submenuId,'child_menu', )}}>{submenu.submenuName}</a></li>
                            );
                          })}
                        </ul>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </>
      );
    }
    return arr;
  };

  const mobileNavbar = () => {
    let arr = [];
    for (let i = 0; i < navData.length; i++) {
      const dropdown = navData[i].dropdown?.menu.map((el) =>
        el.menuName?.split(" ").join("")
      );
      const subDropdown = navData[i].dropdown?.menu.map((el) => {
        return el.submenu.map((el) => el.submenuName);
      });

      arr.push(
        <>
          <ul
            class="navbar-nav me-auto mb-2 mb-lg-0"
            style={{ textAlign: "left" }}
          >
            {/* <div style={{border:'0.5px solid white'}} />  */}
            <hr style={{ margin: "0", color: "white" }} />
            <li
              class="nav-item  justify-content-between d-flex"
              data-bs-dismiss={dropdown?.length == 0 ? "offcanvas" : ""}
              aria-label={dropdown?.length == 0 ? "Close" : ""}
              onClick={() =>
                moveMenuPage(
                  `${navData[i].url}`,
                  navData[i]?.pageName.split(" ").join("")
                )
              }
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <p class="m-0">{navData[i].pageName}</p>
              {dropdown?.length > 0 && (
                <span>
                  {!idToCollapse[navData[i]?.pageName.split(" ").join("")] ||
                    collapse.hidden ==
                    idToCollapse[navData[i]?.pageName.split(" ").join("")] ? (
                    <GoPlus />
                  ) : (
                    <FaMinus />
                  )}
                </span>
              )}
            </li>

            <div
              class={`${idToCollapse[navData[i]?.pageName.split(" ").join("")] ||
                collapse.hidden
                } px-3 mobileNav`}
              id={`${navData[i]?.pageName.split(" ").join("")}`}
              style={{ transition: "visibility 0s, opacity 0.5s linear" }}
            >
              <ul class="navbar-nav">
                {navData[i].dropdown?.menu.map((menu) => {
                  return (
                    <>
                      <li
                        class="nav-item justify-content-between d-flex"
                        id="mobileNavUl"
                        data-bs-dismiss={
                          menu.submenu.length == 0 ? "offcanvas" : ""
                        }
                        aria-label={menu.submenu.length == 0 ? "Close" : ""}
                        style={{
                          transition: "visibility 0s, opacity 0.5s linear",
                          cursor: "pointer",
                        }}
                        onClick={
                          menu?.menuUrl == "#"
                            ? () =>
                              menu.submenu?.length
                                ? openChildMenu(
                                  menu?.menuName?.split(" ").join("")
                                )
                                : moveInternalPage(
                                  "/tgb",
                                  navData[i].en_pageName
                                    .toLocaleLowerCase()
                                    .split(" ")
                                    .join("-"),
                                  menu.menuId,
                                  "sub_menu"
                                )
                            : () => navigate(`${menu?.menuUrl}`)
                        }
                      >
                        <p class="m-0">{menu.menuName}</p>
                        {menu.submenu.length > 0 && (
                          <span>
                            {!idToCollapseChild[
                              menu?.menuName?.split(" ").join("")
                            ] ||
                              collapseChild.hidden ==
                              idToCollapseChild[
                              menu?.menuName?.split(" ").join("")
                              ] ? (
                              <GoPlus />
                            ) : (
                              <FaMinus />
                            )}
                          </span>
                        )}
                      </li>
                      <div
                        class={`${idToCollapseChild[
                          menu?.menuName?.split(" ").join("")
                        ] || collapseChild.hidden
                          } px-3 mobileNav`}
                        id={menu?.menuName?.split(" ").join("")}
                        style={{
                          transition: "visibility 0s, opacity 0.5s linear",
                        }}
                      >
                        <ul class="navbar-nav sub-menu">
                          {menu.submenu?.map((submenu) => {
                            return (
                              <li
                                class="nav-item"
                                style={{ cursor: "pointer" }}
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                onClick={
                                  submenu?.submenuUrl == "#"
                                    ? () => {
                                      moveInternalPage(
                                        "/tgb",
                                        submenu.en_pageName
                                          .toLocaleLowerCase()
                                          .split(" ")
                                          .join("-"),
                                        submenu.submenuId,
                                        "child_menu"
                                      );
                                    }
                                    : () => navigate(`${submenu?.submenuUrl}`)
                                }
                              >
                                {submenu.submenuName}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  );
                })}
              </ul>
            </div>
          </ul>
        </>
      );
    }

    return arr;
  };

  useEffect(() => { }, []);
  return (
    <div class="navbarMain">
      <div class="nav_head" id="nav_head">
        <div class="nav_head_left">
          <div class="nav_logo_div" onClick={() => navigate("/")}>
            <img
              style={{ cursor: "pointer" }}
              src={require("../images/navbar/tripura_bank_logo.png")}
              alt="tripura-bank-logo"
            />
          </div>
          <div class="nav_logo_text" onClick={() => navigate("/")}>
            <h1 class="fw-semibold logoText " style={{ cursor: "pointer" }}>
              <TextTransition springConfig={presets.wobbly}>
                {TEXTS[index % TEXTS.length]}
              </TextTransition>
            </h1>
            <p
              style={{ cursor: "pointer" }}
              class="small_text fw-bold text-start"
            >
              (A Joint Undertaking of Govt. Of India, Punjab National Bank and
              Govt. of Tripura)
            </p>
          </div>
        </div>
        <div class="nav_head_right p-0">
          <div class="nav_head_right_top">
            <ul class="nav_head_top_ul nav_head_top_ul_hide  ">
              <li>
                <p class="mb-2 mt-2 ">Connect-Us:</p>
              </li>
              <li>
                <p class="mb-2 fs-4 px-4" style={{ cursor: "pointer" }}>
                  <a
                    href={`https://tripuragraminbank.org/tgb/24/sub_menu/get-in-touch`}
                  >
                    <BsFillTelephoneFill />
                  </a>
                </p>
              </li>
              <li>
                <p class="mb-2 fs-4" style={{ cursor: "pointer" }}>
                  <a href={`tgraminbank@gmail.com`}>
                    <IoMdMail />
                  </a>
                </p>
              </li>
              <li>
                <p class="mb-2 fs-4 px-4" style={{ cursor: "pointer" }}>
                  <a
                    href={`https://www.facebook.com/profile.php?id=100088090697407`}
                    target="_blank"
                  >
                    <BsFacebook />
                  </a>
                </p>
              </li>
              <li>
                <p class="mb-2 fs-4 " style={{ cursor: "pointer" }}>
                  <a
                    href={`https://www.instagram.com/tgbofficial1976/`}
                    target="_blank"
                  >
                    <GrInstagram />
                  </a>
                </p>
              </li>
              <li>
                <p class="mb-2 fs-4 px-4" style={{ cursor: "pointer" }}>
                  <a
                    href={`https://twitter.com/tgbofficial1976`}
                    target="_blank"
                  >
                    <FaTwitter />
                  </a>
                </p>
              </li>
            </ul>
            <ul class="nav_head_top_ul">
              <li>
                <p class="mb-2">Available In:</p>
              </li>
              <li>
                <p
                  class="mb-2"
                  style={
                    language == "hi"
                      ? {
                        cursor: "pointer",
                        color: "#FFE500",
                        textDecoration: "underline",
                      }
                      : { cursor: "pointer" }
                  }
                  onClick={() => setLanguage("hi")}
                >
                  हिंदी
                </p>
              </li>
              <li>
                <p class="mb-2">|</p>
              </li>
              <li>
                <p
                  class="mb-2"
                  style={
                    language == "bn"
                      ? {
                        cursor: "pointer",
                        color: "#FFE500",
                        textDecoration: "underline",
                      }
                      : { cursor: "pointer" }
                  }
                  onClick={() => setLanguage("bn")}
                >
                  বাংলা
                </p>
              </li>
              <li>
                <p class="mb-2">|</p>
              </li>
              <li>
                <p
                  class="mb-2"
                  style={
                    language == "en"
                      ? {
                        cursor: "pointer",
                        color: "#FFE500",
                        textDecoration: "underline",
                      }
                      : { cursor: "pointer" }
                  }
                  onClick={() => setLanguage("en")}
                >
                  English
                </p>
              </li>
            </ul>
          </div>

          <div class="nav_head_right_bottom p-1">
            <div class="nav_head_right_bottom_1">
              <p class=" fw-semibold mb-2" style={{ color: "#A0313A", fontSize: '12px' }}>
                Sponsored By{" "}
              </p>
              <img
                class="mb-2 mx-2"
                style={{ height: "30px" }}
                src={require("../images/navbar/panjabNationalBank.png")}
                alt="tripura-bank-logo"
              />
            </div>

            <div style={{ position: "relative" }}>
              <div class="inputHead">
                <input
                  type="text"
                  name=""
                  id="inputNavbar"
                  placeholder="Search products Services.."
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyUp={() => navigate("/search-results")}
                />
                <span
                  class="p-2 mx-2 "
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/search-results")}
                >
                  <BsSearch class="mb-1" />
                </span>
              </div>
            </div>

            {login && (
              <button
                class="btn btn-success "
                onClick={() => navigate("/user-profile")}
              >
                <CgProfile class="fs-4" /> <span class="fw-bold">Profile </span>
              </button>
            )}

            {login ? (
              <button class="btn btn-success " onClick={logout}>
                {" "}
                <BiLogOut class="fs-4" /> <span class="fw-bold">Log-out </span>
              </button>
            ) : (
              <button
                class="btn btn-success "
                onClick={() => navigate("/log-in")}
              >
                {" "}
                <BiLogIn class="fs-4" />{" "}
                <span class="fw-bold"> {empButtonName[0].title}</span>
              </button>
            )}

            {/* {login ? "":
              <button
                class="btn btn-success "
                onClick={() => navigate("/log-in")}
              >
                {" "}
                <BiLogIn class="fs-4" />{" "}
                <span class="fw-bold"> {bordButtonName[0].title}</span>
              </button>
            } */}
          </div>
        </div>
      </div>

      <nav class="navbar navbar-expand-lg bg-success p-0">
        <div class="container-fluid p-0">
          <div class="smallNave">
            <div class="nav_logo_divSmall" onClick={() => navigate("/")}>
              <img
                class="mx-1"
                src={require("../images/navbar/tripura_bank_logo.png")}
                alt="tripura-bank-logo"
              />
            </div>
            <div
              class="nav_logo_divSmall2"
              onClick={() => navigate("/")}
              style={{ position: "relative" }}
            >
              <p
                class="text:white fw-bold m-0 mx-2"
                style={{
                  position: "absolute",
                  width: "160px",
                  top: "-10px",
                  fontSize: "13px",
                }}
              >
                <TextTransition springConfig={presets.wobbly}>
                  {TEXTS2[index % TEXTS2.length]}
                </TextTransition>
              </p>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div class="inputHeadSmall">
              <div class="smallScreenHead">
                <span onClick={() => setMsearch(!msearch)}>
                  <BsSearch />
                </span>
                <span
                  class="px-3 fw-bold"
                  onClick={() => navigate("tgb/24/sub_menu/get-in-touch")}
                >
                  <BsTelephone />
                </span>
                {login && (
                  <span onClick={() => navigate("/user-profile")}>
                    <ImProfile />
                  </span>
                )}

                {login ? (
                  <span class="mb-1 px-3" style={{ fontSize: "22px" }}>
                    <FaUserSlash onClick={logout} />
                  </span>
                ) : (
                  <span class="mb-1" style={{ fontSize: "22px" }}>
                    <IoIosContact onClick={() => navigate("log-in")} />
                  </span>
                )}
              </div>
            </div>
            <button
              class="btn btn-succes mobileTogal"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <span>
                <AiOutlineMenu class="fw-bold text-white" />
              </span>
            </button>
          </div>
          <div class="navbar-Menu">{navbar()}</div>
        </div>
      </nav>

      {/* ---------------------- */}
      {msearch ? (
        <div class="mSearch" style={{ background: "#eaffea" }}>
          <div
            class="inputHead d-flex justify-content-between m-0"
            style={{ width: "100%", background: "#eaffea" }}
          >
            <input
              type="text"
              name=""
              id="inputNavbar"
              placeholder="Search products Services.."
              onChange={(e) => setSearch(e.target.value)}
              style={{ width: "100%", background: "#eaffea" }}
              onKeyUp={() => navigate("/search-results")}
            />
            <span
              class="p-2 mx-2 "
              style={{ cursor: "pointer" }}
              onClick={mSearch}
            >
              <BsSearch class="mb-1" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* ------------------------ */}

      <div
        class="offcanvas offcanvas-end bg-success "
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ overflow: "hidden", transition: "1000ms" }}
      >
        <div class="offcanvas-header">
          <div class="d-flex">
            <p class="fs-5 text-white mb-0">Available in :</p>
            <select
              class="mx-2 text-white bg-success border-white"
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="en">English</option>
              <option value="hi">हिंदी</option>
              <option value="bn">বাংলা</option>
            </select>
          </div>
          <button
            type="button"
            class="btn p-2 m-0"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <ImCross class="text-white" />
          </button>
        </div>
        <div class="offcanvas-body p-0">{mobileNavbar()}</div>
      </div>
    </div>
  );
};

export default Navbar;


