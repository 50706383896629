import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  let token = sessionStorage.getItem("Access_token");

  if (!token) {
    return <Navigate to="/log-in" />;
  }

  return <>{children}</>;
};
