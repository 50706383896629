import React from "react";
import { AiFillCaretRight } from "react-icons/ai";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../style/home.css";
import { useNavigate } from "react-router-dom";

const OffringCarousel = ({ data }) => {
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 990 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 990, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div class="offrings">
      <Carousel responsive={responsive} infinite={true}>
        {data.map((el) => {
          return (
            <div class="d-flex offringBox">
              <div class="">
                <img
                  style={{
                    width: "160px",
                    height: "180px",
                    borderRadius: "10px",
                  }}
                  src={el.img2}
                  alt=""
                />
              </div>
              <div class=" text-start row px-3 align-content-between">
                <div>
                  <img data-aos="fade-right" src={el.img} alt="" />
                </div>
                <div>
                  <p
                    class="fs-4 fw-semibold m-0"
                    style={{ lineHeight: "25px" }}
                  >
                    {el.title}
                  </p>
                  <p
                    class="m-0 fw-normal"
                    style={{
                      fontSize: "16px",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <span style={{ color: "rgb(0, 146, 83" }}>
                      <AiFillCaretRight />{" "}
                    </span>{" "}
                    <span class="mx-1  ">{el.text1}</span>
                  </p>
                  <p class="m-0 fw-normal" style={{ fontSize: "16px" }}>
                    <span>
                      <AiFillCaretRight class="text-success" />{" "}
                    </span>{" "}
                    <span class="mx-1">{el.text2}</span>
                  </p>
                </div>
                <div>
                  <button
                    class="btn btn-success"
                    onClick={() => navigate(`/${el.url}`)}
                  >
                    Know More
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default OffringCarousel;
