import './App.css';
import {Routes,Route} from 'react-router-dom' 
import { useEffect, useState } from 'react';    
import Home, { RightSlider } from './components/Home'; 
import Footer from './components/Footer';  
import { PrivateRoute } from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop'; 
import DownloadForm from './components/DownloadForm'; 
import LoginPage from './components/LoginPage';
import ETender from './components/ETender';
import  Profile from './components/Profile'; 
import ForgetPass from './components/ForgetPass'; 
import DynamicPage from './components/products/DynamicPage';
import AtmLocater from './components/AtmLocater';
import BranchLocater from './components/BranchLocater '; 
import Navbar from './components/NavbarNew';
import SearchResults from './components/SearchResults';   
import ECircularsCopy from './components/ECircularsCopy'; 
import EmiCalculator from './components/EmiCalculator';  
import QueryForm from './components/QueryForm';
import QuerySection from './components/QuerySection';
import ApplyNow2 from './components/ApplyNow2';
import ApplyNowSection from './components/ApplyNowSection';
import InoperativeAccounts from './components/InoperativeAccounts';
import UnclaimedDeposit from './components/UnclaimedDeposit';
import AgendaCirular from './components/AgendaCirular';
import LoginBordMember from './components/LoginBordMember';
 
 

function App() {
const [laguage,setLanguage]= useState('en') 
const [search,setSearch] = useState("") 
 

useEffect(() => {
  const handleContextmenu = e => {
      e.preventDefault()
  }
  document.addEventListener('contextmenu', handleContextmenu)
  return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
  }
}, [ ])
   
  return (
    <div className="App">
      <ScrollToTop/>  
      <Navbar lang={setLanguage} searchName={setSearch} /> 
        <Routes>
          <Route path="/" element={<Home lang={laguage}/>}/>  
          <Route path="/download-form-new"  element={<PrivateRoute><DownloadForm lang={laguage}/></PrivateRoute>}/>
          <Route path="/download-form"  element={<PrivateRoute><ECircularsCopy lang={laguage}/></PrivateRoute>}/>
          <Route path="/agenda-cirular"  element={<><AgendaCirular lang={laguage}/></>}/>


          <Route path="/user-profile"  element={<PrivateRoute><Profile/></PrivateRoute>}/>
          <Route path="/log-in"  element={<LoginPage/>}/> 
          <Route path="/boardmember-log-in"  element={<LoginBordMember/>}/> 

          <Route path="/atm-locator"  element={<AtmLocater lang={laguage}/>}/> 
          <Route path="/branch-locator"  element={<BranchLocater lang={laguage}/>}/>  
          <Route path="/search-results"  element={<SearchResults  lang={laguage} searchName={search}/>}/> 
          <Route path="/e-tender"  element={<ETender lang={laguage}/>}/> 
          <Route path="/forget-pasword"  element={<ForgetPass/>}/>  
          <Route path="/apply-now"  element={<ApplyNow2/>}/>   
          <Route path={`/apply-section`}  element={<ApplyNowSection lang={laguage}/>}/> 
          <Route path={`/tgb/:id/:menuType/:pageName/`}  element={<DynamicPage lang={laguage}/>}/> 
          <Route path={`/emi-calculator`}  element={<EmiCalculator lang={laguage}/>}/> 
          <Route path={`/query-form`}  element={<QueryForm lang={laguage}/>}/> 
          <Route path={`/query-section`}  element={<QuerySection lang={laguage}/>}/> 
          <Route path={`/inoperative-accounts`}  element={< InoperativeAccounts  lang={laguage}/>}/> 
          <Route path={'/unclaimed-deposit'} element={<UnclaimedDeposit lang={laguage}/> }/>
        </Routes>  
        <RightSlider/>
      <Footer lang={laguage}/>
    </div>
  );  
}

export default App;
