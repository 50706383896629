import axios from 'axios';
import React, { useState } from 'react'
import swal from 'sweetalert';
import { Table, Pagination } from "react-bootstrap";

import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from 'react-helmet';



const InoperativeAccounts = () => {

    const [details, setDetails] = useState()
    const [table, setTable] = useState()
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const [cap,setCap] = useState(false)

    const InoperativeAccountsDetail = (e) => {
        const { name, value } = e.target;
        setDetails({ ...details, [name]: value });
      };

 
      const InoperativeDetails = () => { 
            axios
              .post("https://www.tripuragraminbank.org/api/inoperative_accounts.php", details)
              .then((res) => {
                if (res.status === 200) { 
                  swal(res.data.responseMessage); 
                  setTable(res.data.responseResult); 
                } 
              })
              .catch((res) => { 
                swal(res.data.responseMessage);
              }); 
      };


    const tBodyData = table?.tBody;
    const tHeadData = table?.tHead;

  const tBodyyy = tBodyData?.filter((el) => {
    if (search == "") {
      return el;
    } else if (el.name.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.city.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.state.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.address.toLowerCase().includes(search.toLowerCase())) {
      return el;
    }
  });

  const totalPages = Math.ceil(tBodyyy?.length / 10);
  const indexOfLastItem = currentPage * 10;
  const indexOfFirstItem = indexOfLastItem - 10;
  const currentItems = tBodyyy?.slice(indexOfFirstItem, indexOfLastItem);
 
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const tBody = currentItems?.map((el, i) => {
    return (
      <tr>
        <td>
          <p class=" m-0">{el?.sr_no}</p>
        </td>
        <td>
          <p class="m-0">{el?.name}</p>
        </td>
        <td>
          <p class="m-0">{el?.address}</p>
        </td>
        <td>
          <p class="m-0">{el?.city}</p>
        </td>
        <td>
          <p class="m-0">{el?.state}</p>
        </td> 
      </tr>
    );
  });


 

 

 

 

  const siteKey = "6LeOlR8pAAAAAApeyh3j4SIPUCE5dcHj6o5H28Jl"
  const secretKey ="6LeOlR8pAAAAAPuH6Jnirw7UmU7vEVWCdiXd7D-j"



 
  function  onCheck() { 
    setCap(true) 
  } 

  
  return (
    <div> 
       <Helmet>
            <meta charSet="utf-8" />
            <title> Inoperative Accounts</title>
            <meta name="description" content="Inoperative Accounts" />
        </Helmet>
        <div class='slidePage p-2' style={{background:'#F5F5F8'}}>
          <div class='col-11 m-auto text-start mt-3'> 
            <p class='fs-3  fw-bold'>Inoperative Accounts</p>
          </div>
        </div>

        <div class='col-lg-6 col-md-8 col-11 m-auto border p-3 text-start my-5 rounded-2'>
            <p>Please enter search text (Account Name and Address)</p>

            <div class='row col-11 m-auto mt-3'>
                <div class='col-md-6'>
                    <p class='text-black'>Name <span class='text-danger'>*</span></p>
                </div>
                <div class='col-md-6'>
                    <input type="text" class='form-control' name='Name' value={details?.Name} onChange={InoperativeAccountsDetail} />
                </div>
            </div>

            <div class='row col-11 m-auto mt-3'>
                <div class='col-md-6'>
                    <p class='text-black'>Address/ City Name (Optional) </p>
                </div>
                <div class='col-md-6'>
                    <input type="text" class='form-control' name='Address' value={details?.Address} onChange={InoperativeAccountsDetail} />
                </div>
            </div>
            

            <div class='row m-auto mt-4 '> 
                <div class=''>
                    <div style={{height:'auto'}}>
                    <ReCAPTCHA style={{width:'100%'}} sitekey={siteKey}  onChange={onCheck} />
                    </div> 
                </div>
            </div>
            <div class='d-flex my-4'> 
                 <button class='btn btn-success m-auto px-4' disabled={cap ? false :true} onClick={InoperativeDetails}>Go</button>
            </div> 
        </div>

        {tBodyData && tBodyData.length > 0 ? 
            <div class="text-start col-11 m-auto">
            <input
                type="text"
                class="form-control"
                placeholder="Search here "
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => setSearch(e.target.value)}
            />
            </div> :""
        }


        <div
          class="mt-4 col-11 m-auto"
          style={{ overflowX: "auto", height: "100%", overflowY: "hidden" }}
        >
          <Table striped bordered hover>
            <thead>
              <tr>
                
                {tHeadData?.map((el) => {
                  return (
                    <th
                      style={{ background: "#BB355A" }}
                      class="text-center text-white"
                    >
                      <p class=" m-0 text-nowrap">{el.headName}</p>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>{tBody}</tbody>
          </Table>
          <div>
            <Pagination>
              {Array.from({ length: totalPages }, (_, i) => (
                <Pagination.Item
                  key={i}
                  active={i + 1 === currentPage}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
    </div>
  )
}

export default InoperativeAccounts