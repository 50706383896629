import axios from "axios";
import md5 from "md5";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import swal from "sweetalert";

const QuerySection = () => {
  const [queryOtpSection, setQueryOtpSection] = useState(true);
  const [querySection, setQuerySection] = useState(false);
  const [refrenceNumber, setRefrenceNumber] = useState("");
  const [otpBtn, setOtpBtn] = useState(false);
  const [otp, setOtp] = useState("");
  const [fillOtp, setFillOtp] = useState("");

  const [query, setQuery] = useState();

  useEffect(() => {
    setOtp(md5(fillOtp));
  }, [fillOtp]);

  const sendOtp = () => {
    axios
      .post(
        `https://www.tripuragraminbank.org/api/otp_check_querysection.php`,
        { RefrenceNo: refrenceNumber }
      )
      .then((response) => {
        if (response.data.responseStatus == true) {
          setOtpBtn(true);
          setOtp(response.data.responseOTP);
          swal(response.data.responseMessage);
        } else {
          swal(response.data.responseMessage);
          setOtpBtn(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const submitOtp = () => {
    axios
      .post("https://www.tripuragraminbank.org/api/check_querysection.php", {
        RefrenceNo: refrenceNumber,
        OTP: otp,
      })
      .then((response) => {
        if (response.data.responseStatus == true) {
          setQueryOtpSection(false);
          setQuerySection(true);
          setQuery(response.data.responseResult);
        } else {
          swal(response.data.responseMessage);
        }
      });
  };

  return (
    <div
      class="text-start px-3"
      style={{ maxWidth: "600px", margin: "15% auto" }}
    >
      <Helmet>
            <meta charSet="utf-8" />
            <title> Query Status</title>
            <meta name="description" content=" Query Status" />
        </Helmet>
      <p class="fs-1 fw-bold ">Check Your Query Status</p>
      {!queryOtpSection ? (
        ""
      ) : (
        <div>
          <label class="mt-2"> Track your Service Request</label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Refrence Number"
              onChange={(e) => setRefrenceNumber(e.target.value)}
            />
            <span>
              <button
                class="btn btn-success"
                style={{ zIndex: "0" }}
                disabled={otpBtn ? true : false}
                onClick={() => sendOtp()}
              >
                Verify
              </button>
            </span>
          </div>
          {!otpBtn ? (
            ""
          ) : (
            <div class="form-group mt-2">
              <input
                type="text"
                class="form-control"
                placeholder="OTP"
                name="PageName"
                maxLength={6}
                onChange={(e) => setFillOtp(e.target.value)}
              />
            </div>
          )}

          <button
            class="btn btn-success form-control mt-3"
            disabled={otpBtn ? false : true}
            onClick={submitOtp}
          >
            Submit
          </button>
        </div>
      )}

      {!querySection ? (
        ""
      ) : (
        <div>
          <table class="table  border ">
            <tr>
              <td>
                <p class="p-2 m-0 fw-bold  ">Status :</p>
              </td>
              <td>
                <p class="p-2 m-0  ">{query?.status}</p>
              </td>
            </tr>
            <tr style={{ background: "#f2f2f2" }}>
              <td>
                <p class="p-2 m-0 fw-bold ">Note :</p>
              </td>
              <td>
                <p class="p-2 m-0 ">{query?.note}</p>
              </td>
            </tr>
          </table>
        </div>
      )}
    </div>
  );
};

export default QuerySection;
