import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { HiSelector } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const ECircularsCopy = ({ lang }) => {
  const [data, setData] = useState();
  const [table, setTable] = useState();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [mainTable, setMainTable] = useState();
  const [order, setOrder] = useState("ASC");

  const navigate = useNavigate()

  const sorting = (el) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[el].toLocaleLowerCase() > b[el].toLocaleLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...data].sort((a, b) =>
        a[el].toLocaleLowerCase() < b[el].toLocaleLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("ASC");
    }
  };

  const sortingDate = (el) => {
    if (order === "ASC") {
      const sorted = [...data].sort(
        (a, b) =>
          a[el].toLocaleLowerCase().split("/").reverse().join("") -
          b[el].toLocaleLowerCase().split("/").reverse().join("")
      );
      setData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...data].sort(
        (a, b) =>
          b[el].toLocaleLowerCase().split("/").reverse().join("") -
          a[el].toLocaleLowerCase().split("/").reverse().join("")
      );
      setData(sorted);
      setOrder("ASC");
    }
  };

  const dis = [];

  for (let i = 0; i < data?.length; i++) {
    dis.push(data[i].category);
  }

  const uniq = dis.filter((item, index) => dis.indexOf(item) === index);

  let Access_token = sessionStorage.getItem("Access_token");


  const atmLocateData = () => {
    axios
      .post(" https://www.tripuragraminbank.org/api/download_forms_table.php", {
        lang: lang,AccessToken :Access_token
      })
      .then((response) => { 
        if(response.data.responseStatus){
          setData(response.data.responseResult.tBody); 
        }else{
          // swal(res.data.responseMessage); 
          navigate('/log-in')
        }
      })
      .catch((error) =>{
        swal('You need to provide valid credentials'); 
      }  );
  };

  const tBody = data
    ?.filter((el) => {
      if (filter.toLocaleLowerCase() == "") {
        return el;
      } else if (
        filter.toLocaleLowerCase() == el.category.toLocaleLowerCase()
      ) {
        return el;
      }
    })
    .filter((el) => {
      if (search == "") {
        return el;
      } else if (el.category.toLowerCase().includes(search.toLowerCase())) {
        return el;
      } else if (el.title.toLowerCase().includes(search.toLowerCase())) {
        return el;
      } else if (el.date.toLowerCase().includes(search.toLowerCase())) {
        return el;
      }
    })
    ?.map((el) => {
      return (
        <tr>
          <td>
            <p class="px-3 py-1 m-0">
              {el.title}{" "}
              {el.new == "New" ? (
                <span class="marquee_new mx-3 text-danger fw-bold">
                  {el.new}
                </span>
              ) : (
                ""
              )}
            </p>
          </td>
          <td>
            <p class="px-3 py-1 m-0">{el.date}</p>
          </td>
          <td>
            <p
              class="text-success px-3 m-0"
              style={{ cursor: "pointer", fontWeight: "500" }}
            >
              <a
                class="text-success"
                href={`${el.url}`}
                target="_blanck"
                download
              >
                Download
              </a>
            </p>
          </td>
        </tr>
      );
    });
  useEffect(() => {
    atmLocateData();
  }, [lang]);

  const description = (el) => {
    setFilter(el);
  };

  return (
    <div class="downloadForm"> 
        <Helmet>
            <meta charSet="utf-8" />
            <title>All Description</title>
            <meta name="description" content="All Description" />
        </Helmet>
      <div class="p-3 text-start" style={{ background: "#f3f3f3" }}>
        <p class="m-0">
          {" "}
          <span>Download Circulars</span>
        </p>
        <p class="fs-2 fw-semibold m-0">Download Circulars</p>
      </div>
      <div class="row">
        <div class="col-md-3 col-11 p-3" style={{ margin: "0 auto" }}>
          <div
            class="text-start p-2"
            onClick={() => description("")}
            style={{ background: "#BE3F62", cursor: "pointer" }}
          >
            <p class="text-white fw-bold m-0">All Category</p>
          </div>
          {uniq?.map((el) => {
            return (
              <div
                class="text-start p-2 border"
                onClick={() => description(`${el}`)}
                style={{ color: "#BE3F62", cursor: "pointer" }}
              >
                <p class="fw-semibold m-0">
                  {el}{" "}
                  {el.new == "New" ? (
                    <span class="marquee_new mx-3 text-danger fw-bold">
                      {el.new}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            );
          })}
        </div>
        <div class="col-md-9 col-11 p-3 " style={{ margin: "0 auto" }}>
          <input
            type="text"
            class="form-control"
            placeholder="Search here "
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => setSearch(e.target.value)}
          />

          <div
            class="mt-4"
            style={{ overflowX: "auto", height: "100%", overflowY: "hidden" }}
          >
            <table class="table " style={{ width: "100%" }} id="sortTable">
              <thead>
                <tr>
                  <th
                    style={{ background: "#f0f0f0" }}
                    onClick={() => sorting("title")}
                  >
                    <p class="px-3 m-0 ">
                      Description{" "}
                      <span>
                        <HiSelector />
                      </span>
                    </p>{" "}
                  </th>
                  <th
                    style={{ background: "#f0f0f0" }}
                    onClick={() => sortingDate("date")}
                  >
                    <p class="px-3 m-0 ">
                      Date{" "}
                      <span>
                        <HiSelector />
                      </span>
                    </p>{" "}
                  </th>
                  <th style={{ background: "#f0f0f0" }}>
                    <p class="px-3 m-0 ">Download</p>
                  </th>
                </tr>
              </thead>
              <tbody>{tBody}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ECircularsCopy;

 
